.login {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  background: #f9f9f9;
  align-items: center;

  .common {
    position: absolute;
    width: 286px;
    height: 382px;
  }

  .topRight {
    right: 0;
    top: 0;

    img {
      width: 100%;
      animation: svg-animate 12s linear infinite forwards;
      -webkit-animation: svg-animate 12s linear infinite forwards;
    }
  }

  .bottomLeft {
    bottom: 0;
    left: 0;

    img {
      width: 100%;
      animation: svg-animate2 15s linear infinite forwards;
      -webkit-animation: svg-animate2 15s linear infinite forwards;
    }
  }
}

@keyframes svg-animate {
  0% {
    opacity: 1;
    transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    -moz-transform: scale(1) translateY(0);
    -webkit-transform: scale(1) translateY(0);
    -o-transform: scale(1) translateY(0);
  }

  50% {
    opacity: 0.8;
    transform: scale(1.1) translateY(-10px);
    -ms-transform: scale(1.1) translateY(-10px);
    -moz-transform: scale(1.1) translateY(-10px);
    -webkit-transform: scale(1.1) translateY(-10px);
    -o-transform: scale(1.1) translateY(-10px);
  }

  75% {
    opacity: 1;
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
  }

  100% {
    opacity: 0.8;
    transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    -moz-transform: scale(1) translateY(0);
    -webkit-transform: scale(1) translateY(0);
    -o-transform: scale(1) translateY(0);
  }
}

@keyframes svg-animate2 {
  0% {
    opacity: 1;
    transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    -moz-transform: scale(1) translateY(0);
    -webkit-transform: scale(1) translateY(0);
    -o-transform: scale(1) translateY(0);
  }

  50% {
    opacity: 0.8;
    transform: scale(1.1) translateY(50px);
    -ms-transform: scale(1) translateY(50px);
    -moz-transform: scale(1) translateY(50px);
    -webkit-transform: scale(1) translateY(50px);
    -o-transform: scale(1) translateY(50px);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    -moz-transform: scale(1) translateY(0);
    -webkit-transform: scale(1) translateY(0);
    -o-transform: scale(1) translateY(0);
  }
}

.loginContent {
  //height: 100%;
  width: 100%;
  /*  display: flex;
    align-items: center;
    justify-content: center;*/
  overflow-y: scroll;
  z-index: 3;
}

.content ::v-deep{
  z-index: 2;
  width: 900px;
  margin: auto;

  .logo img {
    width: 110px;
  }

  .logoT {
    width: 80px;
  }
}

.start {
  background: #fff;
  border-radius: 3vw;
  padding: 40px 50px;
  width: 900px;
  margin-top: 30px;
  justify-content: space-between;
  align-items: normal;
  height: 572px;
  position: relative;

  .left {
    line-height: 1.8;
    color: #14203A;
  }

}

.radioGroup ::v-deep {
  margin-top: 20px;

  .el-radio__label {
    font-size: 16px;
    color: #333333;
  }

  .el-radio__inner {
    border: 1px solid #CCCCCC;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background-color: inherit;

    &::after {
      width: 11px;
      height: 11px;
      background: #FF7B57;
      border-radius: 50%;
    }
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #FF7B57;
  }
}

.startButton {
  display: block;
  color: #fff;
  background-image: linear-gradient(to right, #3088fe, #1fabf5);
  border-radius: 6px;
  font-weight: bold;
  width: 166px;
  height: 45px;
  margin-top: 50px;
  text-decoration: none;
  text-align: center;
  line-height: 45px;
}

.littleFeet {
  margin-top: 50px;
  position: absolute;
  bottom: 50px;

  .feetImgS {
    width: 42px;
    animation: feetImgS 10s linear infinite forwards;
    -webkit-animation: feetImgS 10s linear infinite forwards;
  }

  .feetImgL {
    width: 52px;
    animation: feetImgS 20s ease-in-out infinite forwards;
    -webkit-animation: feetImgS 20s ease-in-out infinite forwards;
    margin-left: 20px;
  }
}

@keyframes feetImgS {
  0% {
    opacity: 0;
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
  }

  50% {
    opacity: 0.8;
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
  }

  75% {
    opacity: 1;
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
  }
}

.right {
  width: 50%;
  text-align: center;

  .mouse {
    margin-top: -25%;
  }
}

.formInline ::v-deep {
  margin-top: 20px;

  .el-form-item__content {
    width: 280px;
  }

  .el-input__inner {
    border-radius: 8px;
    border-color: #999999;
    height: 40px;
  }

  .el-input__icon {
    font-size: 20px;
  }

  .imageCode {
    .el-form-item__content {
      display: flex;
    }

    .el-input__inner {
      width: 170px;
    }

    .code {
      cursor: pointer;
      height: 40px;
    }

    .text {
      text-decoration: underline;
      color: #ED5849;
      width: 100px;
    }
  }

  .btnGroup {
    .el-link {
      margin-left: 20px;
      color: #ED5849;
    }

    .el-button {
      background: linear-gradient(to bottom, #09c3ef, #3088fe);
      font-size: 14px;
      border: none;
    }
  }
}

.footer ::v-deep {
  text-align: center;
  color: #14203a;
  margin: 5% 0 1%;
  font-size: 14px;

  a {
    color: #14203a;
  }
}

.btnGroup {
  position: relative;
  z-index: 5;
}

@media screen and (max-height: 789px) {
  .loginContent {
    height: 100%;
  }
}
